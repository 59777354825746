import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import onClickOutside from "react-onclickoutside"
import Autocomplete from '../../ui/Autocomplete'
import getMeta from '../../utils/Meta'
import axios from 'axios'

// We only allow one dropdown gloobally to show at a time
let globalDropdownShowing = null

class TagSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDropdown: false,
      tagCategories: [],
      showMores: [],
    }
  }

  handleClickOutside = (evt) => {
    if(this.state.showDropdown == true) {
      globalDropdownShowing = null
      this.setState({showDropdown: false})
    }
  }

  showDropdown = (e) => {
    e.preventDefault()

    if(this.state.showDropdown) {
      globalDropdownShowing = null
      this.setState({showDropdown: false})
    } else {
      if(globalDropdownShowing) { globalDropdownShowing.setState({showDropdown: false}) }
      globalDropdownShowing = this
      this.setState({showDropdown: true})
    }
  }

  componentDidMount() {
    this.loadTags()
  }

  loadTags = () => {
    const opts = {
      method: "POST",
      url: '/tags/load',
      headers: { 'X-CSRF-Token': getMeta('csrf-token') }
    }

    return axios(opts)
      .then((response) => {
        this.setState({
          tagCategories: response.data.categories
        })
      }).catch((error) => {
      })
  }

  selectedOptsText = () => {
    if(this.props.selected.length == 0) {
      return <span>{this.props.placeholder}</span>
    } else {
      return <span>{this.props.selected.length} Tag{this.props.selected.length > 0 ? 's': ''}</span>
    }
  }

  selectedBtnClass = () => {
    if(this.props.selected.length == 0) {
      return 'btn btn-default'
    } else {
      return 'btn btn-primary'
    }
  }

  toggleTag = (tag) => {
    if(this.isTagSelected(tag)) {
      this.removeTag(tag)
    } else {
      this.selectTag(tag)
    }
  }

  toggleCategory = (cat, event) => {
    if(event) {
      event.preventDefault()
    }

    let showMores = this.state.showMores

    if(showMores.includes(cat.name)) {
      showMores = showMores.filter((name) => name != cat.name)
    } else {
      showMores = showMores.concat(cat.name)
    }

    this.setState({showMores: showMores})
  }

  selectTags = (tags) => {
    this.props.onSelectedChange(tags)
  }

  selectTag = (tag) => {
    this.props.onSelectedChange(this.props.selected.concat([tag]))
  }

  removeTag = (tag) => {
    const tags = this.props.selected.filter((t) => t.value != tag.value)
    this.props.onSelectedChange(tags)
  }

  isTagSelected = (tag) => {
    return this.props.selected.find((t) => t.value == tag.value)
  }

  selectedTagIn = (tags) => {
    return tags.find((t) => this.props.selected.find((selected) => selected.value == t.value))
  }

  render() {
    return(
      <div>
        <button type="button"
          className={this.selectedBtnClass()}
          onClick={this.showDropdown}
          aria-haspopup="true"
          aria-expanded={this.state.showDropdown}
        >
          {this.selectedOptsText()}
        </button>

        <div className={classnames('dropdown-menu dropdown-menu--xl', {show: this.state.showDropdown}) }>
          <div
            key="randomkey"
            className="close filter-close px-4"
            onClick={(e) => this.handleClickOutside(e)}>
            <i className="fa fa-check"></i> Done
          </div>
          <div className="card-body">
            <div className="mb-3">
              <Autocomplete
                showOnFocus={false}
                url={"/tags/search"}
                placeholder="Search Tags"
                initialOptions={this.props.selected}
                selected={this.props.selected}
                onSelectedChange={(v) => this.selectTags(v)}
              />
            </div>
            {this.state.tagCategories.map((cat) =>
              <div className="cat" key={cat.name}>
                <h3 className="h5">{cat.name}</h3>
                <div className="tags" key={cat.name}>
                  {cat.tags.slice(0,8).map((tag) =>
                    <span key={tag.value} className={classnames('tag', {"tag--selected": this.isTagSelected(tag)})} onClick={(e) => this.toggleTag(tag)}>
                      <span className="hash-tag">#</span>
                      {tag.text}
                    </span>
                  )}
                  <div className={classnames({"d-none": !this.selectedTagIn(cat.tags.slice(9,30)) && !this.state.showMores.includes(cat.name)})}>
                    {cat.tags.slice(9,30).map((tag) =>
                      <span key={tag.value} className={classnames('tag', {"tag--selected": this.isTagSelected(tag)})} onClick={(e) => this.toggleTag(tag)}>
                        <span className="hash-tag">#</span>
                        {tag.text}
                      </span>
                    )}
                  </div>
                  {cat.tags.length > 9 && !this.selectedTagIn(cat.tags.slice(9,30)) &&
                    <div className={classnames('mt-3 font-size-sm', {"d-none": this.state.showMores.includes(cat.name)})}>
                      <a href="#" onClick={(e) => this.toggleCategory(cat, e)}>Show More</a>
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default onClickOutside(TagSelect)
