import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'

class Modal extends React.Component {
	constructor(props) {
		super(props)
	}

  render() {
  	const modalSize = "modal-" + (this.props.modalSize || "lg")

    const style = {}
  	if(this.props.isOpen) {
  		style.display = "block"
  	}

		return(
			<div className={classnames('modal fade', {'show': this.props.isOpen})} id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby={this.props.modalId + "-title"} aria-hidden={this.props.isOpen} style={style}>
			  <div className={'modal-dialog modal-dialog-centered ' + modalSize} role="document">
			    <div className="modal-content">
			      <div className="modal-header">
			        <h5 className="modal-title" id={this.props.modalId + "-title"}>{this.props.title}</h5>
			        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onClose}>
			          <span aria-hidden="true">&times;</span>
			        </button>
			      </div>
			        {this.props.children}
			    </div>
			  </div>
			</div>
		)
  }
}

export default Modal