function createURL(url, ...rest) {
  let param = new URLSearchParams(getEntries(rest)).toString();
  if (param) { url += "?" + param }
  return url
}

function getEntries(o = {}) {
  const entries = [];
  for (const [k, v] of Object.entries(o)) {
    if (Array.isArray(v))
      entries.push(...v.flatMap(getEntries))
    else if (typeof v === 'object')
      entries.push(...getEntries(v))
    else entries.push([k, v])
  }
  return entries;
}

export default createURL