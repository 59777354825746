import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'

function getMeta(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

class MultiEmailInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasFocus: false, // Does the input have focus currently
      inputValue: "", // What the user has typed in the input
      emails: [], // Optionally used if 'selected' is not passed in via props
      error: false,
      placeholder: "Enter email addresses"
    }

    this.inputRef = React.createRef() // Needed so we can focus the input
  }

  handleChange = (event) => {
    this.setState({inputValue: event.target.value})
  }

  handleKeyDown = (event) => {
    if(event.keyCode == 8 && this.state.emails.length > 0 && this.state.inputValue.length == 0) {
      this.handleRemoveSelected(this.state.emails.length  - 1)
    }
  }

  handleKeyPress = (event) => {
    if(["Enter", "Tab", ","].includes(event.key)) {
      event.preventDefault()
      this.addEmail({text: this.state.inputValue, value: this.state.inputValue})
    }
  }

  handlePaste = (event) => {
    event.preventDefault()
    let paste = (event.clipboardData || window.clipboardData).getData('text');
    const emails = paste.split(/[\s,]/)
    const newEmails = []
    emails.map((email) => {
      const trimmed = email.trim()
      if(trimmed.length > 0 && trimmed.includes("@") > 0) {
        newEmails.push({text: trimmed, value: trimmed})
      }
    })
    this.setState({emails: [...this.state.emails, ...newEmails]})
  }

  handleBlur = () => {
    this.addEmail({text: this.state.inputValue, value: this.state.inputValue})
    this.setState({hasFocus: false})
  }

  addEmail = (email) => {
    if(email && email.value.indexOf("@") > 0) {
      const array = [...this.state.emails].concat(email)
      this.setState({emails: array, inputValue: ""})
    }
  }

  handleRemoveSelected = (index) => {
    const array = [...this.state.emails]
    array.splice(index, 1)
    this.setState({emails: array})
  }

  handleClick = () => {
    this.inputRef.current.focus()
    this.setState({hasFocus: true})
  }

  render() {
    const showPlaceholder = this.state.emails.length == 0 && this.state.inputValue.length == 0
    const placeholderText = this.props.placeholder || this.state.placeholder
    const inputWidth = showPlaceholder ? (placeholderText.length * 8) + 10 : ((this.state.inputValue.length * 8) + 10)
    const hasError = this.props.error || this.state.error

    return (
      <div className="selectize-control multi plugin-remove_button" onClick={this.handleClick} id={this.props.divId || "geo-autosearch"}>
        <div className={classnames("selectize-input", "items", {"focus": this.state.hasFocus, "has-items": this.state.emails.length > 0})}>
          {this.state.emails.map((opt, index) =>
            <div className="item" data-value key={opt.value}>
              {opt.text}
              <span onClick={(evt) => this.handleRemoveSelected(index)} className="remove">&times;</span>
              <input type="hidden" name="email[]" value={opt.value} />
            </div>
          )}
          <input
            className={classnames({"is-invalid": hasError})}
            style={{ width: inputWidth + "px", opacity: '1', position: 'relative', left: '0px' }}
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder={showPlaceholder ? placeholderText : ""}
            ref={this.inputRef}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onPaste={this.handlePaste}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            value={this.state.inputValue}
            >
          </input>
        </div>
      </div>
    )
  }
}

export default MultiEmailInput

// this.props.disabled
// this.props.placeholder
// this.props.initialOptions
// this.props.onSearchChange
// this.props.onSelectedChange
