import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import onClickOutside from "react-onclickoutside"

// We only allow one dropdown gloobally to show at a time
let globalDropdownShowing = null

class DropdownSelect extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selected: [],
      showDropdown: false
    }
  }

  handleClickOutside = (evt) => {
    if(this.state.showDropdown == true) {
      globalDropdownShowing = null
      this.setState({showDropdown: false})
    }
  }

  showDropdown = (e) => {
    e.preventDefault()

    if(this.state.showDropdown) {
      globalDropdownShowing = null
      this.setState({showDropdown: false})
    } else {
      if(globalDropdownShowing) { globalDropdownShowing.setState({showDropdown: false}) }
      globalDropdownShowing = this
      this.setState({showDropdown: true})
    }
  }

  selectedOptions = () => {
    return this.props.options.filter((o) => this.selectedValues().indexOf(o.value) >= 0)
  }

  selectedValues = () => {
    return this.props.selected || this.state.selected
  }

  isOptionSelected = (opt) => {
    return this.selectedValues().find((value) => value == opt.value)
  }

  isValueSelected = (value) => {
    return this.props.selected.find((o) => o == value)
  }

  optionSelectedIcon = (opt) => {
    if(this.isOptionSelected(opt)) {
      return <i className="far fa-check-circle icon"></i>
    } else {
      return <i className="far fa-circle icon"></i>
    }
  }

  setSelected = (e, opt) => {
    e.preventDefault()

    let selected = [...this.selectedValues()]

    if(!this.isValueSelected(opt.value)) {
      if(this.props.singleSelect) {
        selected = [opt.value]
      } else {
        selected.push(opt.value)
      }
    } else {
      selected = selected.filter((value) => value != opt.value)
    }

    if(this.props.onChange) {
      this.props.onChange(selected)
    } else {
      this.setState({selected: selected})
    }
  }

  selectedOptsText = () => {
    if(this.selectedOptions().length == 0) {
      return <span>{this.props.placeholder}</span>
    } else {
      return <span>{this.selectedOptions().map((o) => o.text).join(", ")}</span>
    }
  }

  btnIconClass = () => {
    if(this.props.icon) {
      return <i className={this.props.icon}></i>
    } else {
      return ""
    }
  }

  selectedBtnClass = () => {
    if(this.selectedOptions().length == 0) {
      return 'btn btn-default'
    } else {
      return 'btn btn-primary'
    }
  }

  render() {
    return(
      <div>
        <button type="button"
          className={this.selectedBtnClass()}
          onClick={this.showDropdown}
          aria-haspopup="true"
          aria-expanded={this.state.showDropdown}
        >
          {this.btnIconClass()}
          {this.selectedOptsText()}
        </button>
        <div className={classnames('dropdown-menu', {show: this.state.showDropdown})}>
          <div
            className="close filter-close"
            onClick={(e) => this.handleClickOutside(e)}>
            <i className="fa fa-check"></i> Done
          </div>
          {this.props.options.map((opt) =>
            <a
              key={opt.value}
              className={classnames('dropdown-item', {active: this.isOptionSelected(opt)})}
              href="#"
              onClick={(e) => this.setSelected(e, opt)}>
              {this.optionSelectedIcon(opt)}
              {opt.text}
            </a>
          )}
        </div>
      </div>
    )
  }
}

export default onClickOutside(DropdownSelect)