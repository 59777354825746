import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import DropdownSelect from './filters/DropdownSelect'
import axios from 'axios'
import GeoAutocomplete from '../itinerary/inputs/GeoAutocomplete'
import Autocomplete from '../ui/Autocomplete'
import TagSelect from './filters/TagSelect'
import getMeta from '../utils/Meta'
import createURL from '../utils/Urls'

window.filterToGeoId = (name, geoId) => {
  window.FilterBar.setData({places: [{text: name, value: geoId, type: "city"}]})
}

window.boundsChanged = (bounds) => {
  if(window.FilterBar && window.FilterBar.state && window.FilterBar.state.search.use_bounds) {
    window.FilterBar.setData({bounds: bounds})
  }
}

window.useBounds = (value) => {
  if(value) {
    window.FilterBar.setData({use_bounds: value, bounds: Itinsy.Map.getMap().getBounds()})
  } else {
   window.FilterBar.setData({use_bounds: false, bounds: null})
  }
}

window.addFilterTag = (tag) => {
  window.FilterBar.addTag({value: tag, text: tag})
}

class GeosearchPortal extends React.Component {
  constructor(props) {
    super(props)

    this.el = document.getElementById(props.id)
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el
    );
  }
}

class FilterBar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: {
        who: this.props.who || [],
        how_long: this.props.how_long || [],
        when: this.props.when || [],
        places: this.props.places || [],
        cost: this.props.cost || [],
        tags: this.props.tags || [],
        sort: this.props.sort || [],
        bounds: this.props.bounds || null,
        use_bounds: this.props.use_bounds || false
      },
      dropdownShowing: false
    }

    window.FilterBar = this
  }

  clearFilters = () => {
    const values = {
      who: [],
      how_long: [],
      when: [],
      cost: [],
      tags: [],
      sort: [],
    }
    const search = {...this.state.search, ...values}
    this.setState({search: search}, () => this.search())
  }

  setData = (values) => {
    const search = {...this.state.search, ...values}
    this.setState({search: search}, () => this.search())
  }

  addTag = (newTag) => {
    if(!this.state.search.tags.find((tag) => tag.value == newTag.value)) {
      const tags = {tags: this.state.search.tags.concat(newTag)}
      const search = {...this.state.search, ...tags}
      this.setState({search: search}, () => this.search())
    }
  }

  onGeoChange = (data) => {
    const search = {...this.state.search, ...{places: data}}
    this.setState({search: search}, () => this.search())
  }

  closeMainNav = () => {
    $('#header').removeClass("nav-open")
    this.search()
  }

  search = () => {
    $('#search-in-progress').show();
    $('#search-results').hide();

    const search = this.state.search

    const params = {
      cost: search.cost.join(","),
      how_long: search.how_long.join(","),
      tags: search.tags.map((t) => t.value).join("|"),
      who: search.who.join(","),
      when: search.when.join(","),
      sort: search.sort.join(",")
    }

    if(search.use_bounds) {
      params.use_bounds = search.use_bounds
      params.bounds = search.bounds
    }

    params.cities_ids = search.places.filter((p) => p.type == "city").map((o) => o.value).join("|")
    params.countries_ids = search.places.filter((p) => p.type == "country").map((o) => o.value).join("|")

    const opts = {
      method: "get",
      url: this.props.url,
      params: params,
      headers: {
        'Accept': '*',
        'X-CSRF-Token': getMeta('csrf-token')
      }
    }

    return axios(opts)
      .then((response) => {
        eval(response.data)
        $('#search-in-progress').hide();
        $('#search-results').show();
        window.history.replaceState(null, null, createURL(this.props.url.replace(".js",""), params));
      })
      .catch((error) => {
        $('#search-in-progress').hide();
        alert("There was an error searching, please try again.")
     })
  }


  render() {
    return(
      <div className="filter-bar">

        {!this.props.hideGeoSearch &&
          <GeosearchPortal id="geo-search-portal">
            <GeoAutocomplete
              divId="header-search"
              selected={this.state.search.places}
              onSelectedChange={this.onGeoChange}
              magnifierClick={this.closeMainNav}
            />
          </GeosearchPortal>
        }

        {this.props.showGeoPane &&
          <GeosearchPortal id="geo-search-portal-pane">
            <GeoAutocomplete
              divId="header-search-pane"
              selected={this.state.search.places}
              onSelectedChange={this.onGeoChange}
            />
          </GeosearchPortal>
       }

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex filter-bar__filters">
                <div className="btn btn-default d-lg-none pane-toggle" data-pane-target="#main-search">
                  <i className="fa fa-search"></i>
                </div>
                <div className="btn-group">
                  <DropdownSelect
                    options={this.props.options.when}
                    selected={this.state.search.when}
                    placeholder="Months"
                    onChange={(v) => this.setData({when: v})}
                  />
                </div>

                <div className="btn-group">
                  <DropdownSelect
                    options={this.props.options.how_long}
                    selected={this.state.search.how_long}
                    placeholder="Duration"
                    onChange={(v) => this.setData({how_long: v})}
                  />
                </div>

                <div className="btn-group">
                  <DropdownSelect
                    options={this.props.options.who}
                    selected={this.state.search.who}
                    placeholder="Travelers"
                    onChange={(v) => this.setData({who: v})}
                  />
                </div>
                <div className="btn-group">
                  <DropdownSelect
                    options={this.props.options.cost}
                    selected={this.state.search.cost}
                    placeholder="Cost"
                    onChange={(v) => this.setData({cost: v})}
                  />
                </div>
                <div className="btn-group">
                  <TagSelect
                    url={'/tags/search.json'}
                    options={this.props.options.tags}
                    selected={this.state.search.tags}
                    placeholder="Advanced"
                    onSelectedChange={(v) => this.setData({tags: v})}
                  />
                </div>
                {this.props.options.sort && this.props.options.sort.length > 0 &&
                  <div className="btn-group">
                    <DropdownSelect
                      singleSelect={true}
                      options={this.props.options.sort}
                      selected={this.state.search.sort}
                      placeholder="Sort"
                      icon="fas fa-sort-amount-down icon"
                      onChange={(v) => this.setData({sort: v})}
                    />
                  </div>
                }
                <div className="text-primary btn btn-default d-none d-md-block" style={{border: 'none'}} onClick={this.clearFilters}>
                  Clear filters
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default FilterBar