import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

class ContactsList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
    }
  }

  render() {
  	return(
  		<div style={{"maxHeight": "450px", "minHeight": "450px", "overflowY": "scroll"}}>
        <div className="p-3 px-lg-4">
    		  {this.props.contacts.map((contact) =>
            <div className={classnames('d-flex align-items-center')} key={contact.id}>
              <div className="mr-3">
                <input
                  type="checkbox"
                  onChange={(e) => this.props.onSelect(contact.email, e.target.checked)}
                  checked={this.props.selectedContacts.includes(contact.email)}
                />
              </div>
              {contact.photo &&
                <div className="mr-3">
                  <figure className={classnames('user-avatar user-avatar--50 mb-0')}>
                    <img src={contact.photo} />
                  </figure>
                </div>
              }
              <div className={classnames('d-flex flex-grow-1 py-4 border-bottom border-light')}>
                <strong className="flex-grow-1">
                  {contact.name} - {contact.email}
                </strong>
              </div>
            </div>
    		  )}
        </div>
  	  </div>
  	)
  }
}

export default ContactsList