import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'
import onClickOutside from "react-onclickoutside"

function getMeta(metaName) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaName) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

class Autocomplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hasFocus: false, // Does the search input have focus currently
      searchValue: "", // What the user has typed in the search input
      selectedOptions: [], // Optionally used if 'selected' is not passed in via props
      options: this.props.initialOptions || [], // What is shown in the dropdown of autocomplete
      showOptions: false, // Whenther the dropdown of options is showing or not
      highlightedOptionIndex: -1, // If we've highlighted (using keyboard) an option in the dropdown
      error: false
    }

    this.initialOptions = props.options
    this.inputRef = React.createRef() // Needed so we can focus the input
  }

  // Generated by the react-onclickoutside thing
  handleClickOutside = (evt) => {
    this.setState({showOptions: false})
  }

  onSearchChange = () => {
    if(this.props.maxSelected && this.selectedOptions().length >= this.props.maxSelected) { return }

    if(this.state.searchValue.length == 0) {
      this.resetAutocomplete()
      return
    }

    this.setState({showOptions: true})

    if(this.props.url) {
      const opts = {
        method: 'get',
        url: this.props.url,
        params: {q: this.state.searchValue},
        headers: { 'X-CSRF-Token': getMeta('csrf-token') }
      }
      axios(opts)
        .then((response) => {
          const results = response.data.results
          this.setState({options: results})
        })
        .catch((error) => {
          console.log(error)
        })
    }
    else if(this.props.onSearchChange) {
      this.props.onSearchChange(this.state)
    } else {
      const q = this.state.searchValue.toLowerCase()
      const opts = this.props.initialOptions.filter((o) => o.text.toLowerCase().includes(q) )
      this.setState({options: opts})
    }
  }

  handleChange = (event) => {
    if(this.props.maxSelected && this.selectedOptions().length >= this.props.maxSelected) { return }

    this.setState({searchValue: event.target.value}, () => this.onSearchChange())
  }

  handleKeyDown = (event) => {
    if(event.keyCode == 38) {
      // up key
      event.preventDefault()
      let nextIndex = this.state.highlightedOptionIndex - 1
      const options = this.options()
      if(options && nextIndex > options.length) { nextIndex = 0 }
      this.setState({highlightedOptionIndex: nextIndex})
    } else if(event.keyCode == 40) {
      // Down key
      event.preventDefault()
      let nextIndex = this.state.highlightedOptionIndex + 1
      if(nextIndex < 0) { nextIndex = -1 }
      this.setState({highlightedOptionIndex: nextIndex})
    } else if(event.keyCode == 46 || event.keyCode == 8) {
      if(this.selectedOptions().length > 0 && this.state.searchValue.length == 0) {
        event.preventDefault()
        this.handleRemoveSelected(this.selectedOptions().length - 1)
      }
    }
  }

  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      event.preventDefault()
      if(this.state.highlightedOptionIndex > -1) {
        const options = this.options()
        const addition = {...options[this.state.highlightedOptionIndex]}
        this.addSelectedOption(addition)
        this.resetAutocomplete()
      }
      else if(this.state.searchValue.length > 0) {
        this.addSelectedOption({text: this.state.searchValue, value: this.state.searchValue, custom: true})
        this.resetAutocomplete()
      } else if(this.state.searchValue.length == 0) {
        if(this.props.onSubmit) {
          this.props.onSubmit()
        }
      }
    }
  }

  handleClick = () => {
    this.inputRef.current.focus()
    this.setState({hasFocus: true, showOptions: this.props.showOnFocus})
  }

  handleFocus = () => {
    this.setState({hasFocus: true, showOptions: this.props.showOnFocus})
    // On mobile scroll the element to the top
    if (window.innerWidth < 768 && jQuery && $("#top-search").length > 0) {
      setTimeout(function() {
        document.scrollingElement.scrollTop = $("#top-search").offset().top - 20
      }, 100);
    }
  }

  handleBlur = () => {
    this.setState({hasFocus: false})
  }

  resetAutocomplete = () => {
    this.setState({searchValue: "", options: this.props.initialOptions || [], showOptions: false, highlightedOptionIndex: -1})
  }

  setSelectedOptions = (options) => {
    if(this.props.onSelectedChange) {
      this.props.onSelectedChange(options)
    } else {
      this.setState({selectedOptions: options})
    }
  }

  addSelectedOption = (opt) => {
    const array = [...this.selectedOptions()].concat(opt)
    this.setSelectedOptions(array)
    this.resetAutocomplete()
  }

  handleSelectOption = (evt, opt) => {
    if(evt) { evt.stopPropagation() }
    this.addSelectedOption(opt)
  }

  selectedOptions = () => {
    return this.props.selected || this.state.selectedOptions || []
  }

  isValueSelected = (value) => {
    return this.selectedOptions().find((opt) => opt.value == value)
  }

  options = () => {
    const options = this.props.options || this.state.options || []
    // Don't include selected options in the dropdown
    return options.filter((o) => !this.isValueSelected(o.value))
  }

  handleRemoveSelected = (index) => {
    const array = [...this.selectedOptions()]
    array.splice(index, 1)
    this.setSelectedOptions(array)
  }

  render() {
    const selectedOptions = this.selectedOptions()
    const showPlaceholder = selectedOptions.length == 0 && this.state.searchValue.length == 0
    const placeholderText = this.props.placeholder
    const inputWidth = showPlaceholder ? (placeholderText.length * 8) + 10 : ((this.state.searchValue.length * 8) + 10)
    const hasError = this.props.error || this.state.error

    return (
      <div className="selectize-control multi plugin-remove_button" onClick={this.handleClick} id={this.props.divId || "geo-autosearch"}>
        <div className={classnames("selectize-input", "items", {"focus": this.state.hasFocus, "has-items": selectedOptions.length > 0})}>
          {selectedOptions.map((opt, index) =>
            <div className="item" data-value key={opt.value}>
              {opt.icon &&
                <i className={classnames(opt.icon, '')}></i>
              }
              {opt.text}
              <span onClick={(evt) => this.handleRemoveSelected(index)} className="remove">&times;</span>
            </div>
          )}

          <input
            disabled={this.props.disabled}
            className={classnames({"is-invalid": hasError})}
            style={{ width: inputWidth + "px", opacity: '1', position: 'relative', left: '0px' }}
            type="text"
            placeholder={showPlaceholder ? placeholderText : ""}
            ref={this.inputRef}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            onKeyDown={this.handleKeyDown}
            value={this.state.searchValue}>
          </input>
          {!this.props.hideMagnifier &&
            <button className="geo-submit">
              <i className="fa fa-search" onClick={this.props.magnifierClick}></i>
            </button>
          }
        </div>

        <div className="selectize-dropdown multi" style={{width: "100%", display: this.state.showOptions ? "block" : "none"}}>
          <div className="selectize-dropdown-content">
            {this.options().map((opt, index) =>
              <div
                className={classnames('option', {active: index == this.state.highlightedOptionIndex})}
                value={opt.value}
                data-selectable
                key={opt.value}
                onClick={(evt) => this.handleSelectOption(evt, opt)}>
                {opt.icon ?
                  <i className={classnames(opt.icon, '')}></i>
                  :
                  <i className="fa fa-dot mr-1"></i>
                }
                {opt.text}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default onClickOutside(Autocomplete)

// this.props.disabled
// this.props.placeholder
// this.props.initialOptions
// this.props.onSearchChange
// this.props.onSelectedChange
