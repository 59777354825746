import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import classnames from 'classnames';

import getMeta from '../utils/Meta'
import Modal from '../bootstrap/Modal';
import ContactsList from './ContactsList';

import fake_data from './fake_data.json'

class ContactsImport extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
      authenticated: false,
      retrievingContacts: false,
      invitingContacts: false,
      searchValue: "",
      allContacts: [],
      contacts: [],
      selectedContacts: [],
    }
  }

  // Note: For development the host has to be localhost:3000 for this to work

  componentDidMount = () => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey:  this.props.apiKey,
        clientId: this.props.clientId,
        discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/people/v1/rest"],
        scope: 'https://www.googleapis.com/auth/contacts.readonly'
      }).then(() => {
      }, function(error) {
        alert(JSON.stringify(error, null, 2))
      });
    })
  }

  closeModal = () => {
    this.setState({modalOpen: false})
  }

  fakeGetContacts = () => {
    this.setState({retrievingContacts: true})
    setTimeout(() => {
      this.mapContacts(fake_data)
      this.setState({retrievingContacts: false})
    }, 1000)
  }

  getContacts = () => {
    //this.fakeGetContacts()
    //return
    gapi.client.people.people.connections.list({
       'resourceName': 'people/me',
       'pageSize': 1000,
       'personFields': 'names,emailAddresses,photos',
     }).then((response) => {
        this.mapContacts(response.result.connections)
        this.setState({retrievingContacts: false})
     });
  }

  inviteSelected = () => {
    const url = `/portal/invitations.json`

    const opts = {
      method: "POST",
      url: url,
      headers: { 'X-CSRF-Token': getMeta('csrf-token') },
      data: {email: this.state.selectedContacts}
    }

    this.setState({invitingContacts: true})

    return axios(opts)
      .then((response) => {
        this.setState({invitingContacts: false})
        window.location = response.data.redirect
      }).catch((error) => {
        this.setState({invitingContacts: false})

        if(error.response && error.response.data.message) {
          alert(error.response.data.message)
        } else {
          alert("There was an error inviting your contacts, please try again.")
        }
        return false
      })
  }

  onSearch = (search) => {
    let lowercase = search.toLowerCase()
    const filteredContacts = this.state.allContacts.filter((c) => {
      return (c["name"] && c["name"].toLowerCase().includes(lowercase)) || (c["email"] && c["email"].toLowerCase().includes(lowercase))
    })
    this.setState({searchValue: search, contacts: filteredContacts})
  }

  mapContacts = (contacts) => {
    let entries = []

    contacts.forEach((contact) => {
      if(contact["names"] && contact["names"].length > 0) {
        const name = contact["names"][0]["displayName"]
        const lastName = contact["names"][0]["familyName"] ? contact["names"][0]["familyName"].toUpperCase() : ""
        let email = null
        let photo = null
        if(contact["emailAddresses"] && contact["emailAddresses"].length > 0) {
          const primary = contact["emailAddresses"].find((email) => email["metadata"]["primary"] == true)
          if(primary) { email = primary.value }
        }
        if(contact["photos"] && contact["photos"].length > 0) {
          const primary = contact["photos"].find((email) => email["metadata"]["primary"] == true)
          if(primary) { photo = primary.url }
        }
        if(email) {
          entries.push({name: name, email: email, photo: photo, lastName: lastName, id: contact["resourceName"]})
        }
      }
    })

    entries.sort((a, b) => {
      if (a.lastName < b.lastName) { return -1; }
      if (a.lastName > b.lastName) { return 1; }
      return 0;
    })

    this.setState({allContacts: entries, contacts: entries})
  }

  signIn = () => {
    gapi.auth2.getAuthInstance().signIn().then(() => {
      this.setState({modalOpen: true})
      this.getContacts()
    })
  }

  selectContact = (contact, select) => {
    if(select) {
      if(!this.state.selectedContacts.includes(contact.email)) {
        this.setState({selectedContacts: this.state.selectedContacts.concat(contact)})
      }
    } else {
      this.setState({selectedContacts: this.state.selectedContacts.filter((c) => c != contact)})
    }
  }

  render() {
    return(
      <div>
        <button className="btn btn-google google-login-button d-block w-100" onClick={this.signIn}>
          <i className="fab fa-google mr-2"></i>
          Import From Google Contacts
        </button>

        <Modal isOpen={this.state.modalOpen} modalId="contacts" title="Import Google Contacts" onClose={this.closeModal} >
          {this.state.retrievingContacts ?
            <div className="p-6 text-center">
              <i className="fa fa-spinner fa-2x fa-spin text-success" />
              <div className="mt-4">
                <strong>Retrieving Contacts...</strong>
              </div>
            </div>
            :
            <>
              <div className="ml-3 mr-3 mt-3">
                <input className="form-control" value={this.searchValue} onChange={(e) => this.onSearch(e.target.value)} placeholder="Search" />
              </div>
              <ContactsList contacts={this.state.contacts} selectedContacts={this.state.selectedContacts} onSelect={this.selectContact} />
            </>
          }
          <div className="modal-footer border-top border-light justify-content-start p-3 px-lg-4">
            <button className="btn btn-primary mr-4" onClick={this.inviteSelected}>Invite Selected Contacts</button>
            <div className="text-muted" onClick={this.closeModal}>Cancel</div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default ContactsImport