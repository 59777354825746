import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import axios from 'axios'
import Autocomplete from '../ui/Autocomplete'
import GeoAutocomplete from '../itinerary/inputs/GeoAutocomplete'

function createURL(url, ...rest) {
  let param = new URLSearchParams(getEntries(rest)).toString();
  if (param) { url += "?" + param }
  return url
}

function getEntries(o = {}) {
  const entries = [];
  for (const [k, v] of Object.entries(o)) {
    if (Array.isArray(v))
      entries.push(...v.flatMap(getEntries))
    else if (typeof v === 'object')
      entries.push(...getEntries(v))
    else entries.push([k, v])
  }
  return entries;
}

class HeaderSearch extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      search: {
        places: this.props.places || [],
      },
      dropdownShowing: false,
    }
  }

  setData = (values) => {
    const search = {...this.state.search, ...values}
    this.setState({search: search})
  }

  onGeoChange = (data) => {
    const search = {...this.state.search, ...{places: data}}
    this.setState({search: search})
  }

  doSearch = () => {
    const search = this.state.search
    const params = {}

    params.cities_ids = search.places.filter((p) => p.type == "city").map((o) => o.value).join("|")
    params.countries_ids = search.places.filter((p) => p.type == "country").map((o) => o.value).join("|")

    window.location = createURL('/search', params)
  }

  render() {
    return(
      <GeoAutocomplete
        divId="header-search"
        selected={this.state.search.places}
        onSelectedChange={this.onGeoChange}
        magnifierClick={this.doSearch}
        onSubmit={this.doSearch}
      />
    )
  }
}

export default HeaderSearch