PageComponents = {
  initialize: function() {
    $(".pane-toggle").on('click', function() {
      var target = $(this).data('pane-target');

      // handle cases when tabbar isn't used for sliding panels
      // like in the form wizard navigation
      if ( target == null ) {
        return
      }

      var $toggle = $('.pane-toggle[data-pane-target="' + target + '"]');
      var activeClass = 'active';

      if ( $toggle.hasClass(activeClass) ) {
        $toggle.removeClass(activeClass);
        $(target).removeClass(activeClass);
        return;
      } else {
        var $activePaneToggle = $('.pane-toggle.' + activeClass);
        if ( $activePaneToggle.length ) {
          var openPanes = $activePaneToggle.data('pane-target');
          $activePaneToggle.removeClass(activeClass)
          $(openPanes).removeClass(activeClass);
        }

        $toggle.addClass(activeClass);
        $(target).addClass(activeClass);
        return;
      }
    })

    window.addEventListener('click', function(e){
      if (!document.getElementById('site-nav-dropdown__nav').contains(e.target)){
        var checkbox = document.getElementById('site-nav-dropdown');
        if (checkbox.checked == true) {
          checkbox.checked = false;
        }
      }
    });

    if ( $('body.transparent-header') ) {
      $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        var windowH = window.innerHeight - 100;
        var $body = $("body.transparent-header");
        var cssClass = 'transparent-header__add-white'

        if (scroll >= (windowH/2)) {
          $body.addClass(cssClass);
        } else {
          $body.removeClass(cssClass);
        }
      });
    }

    if (document.getElementById('header') !== null) {
      $("#header menu").on('click', function() {
        $('#header').toggleClass('nav-open');
      })
    }

    $('body').on('click', '.links-external a', function(event) {
      event.preventDefault();
      event.stopPropagation();
      window.open(this.href, '_blank');
    })

    window.addEventListener("message", (event) => {
      console.log("message")
      if (event.origin !== "https://community.itinsy.com") {
        return;
      }
      console.log(event.data)
      var data = event.data

      if(data.action == "tribe.notification.updateCount" && data.widgetId == "notifications-widget-iframe") {
        if(data.count && data.count > 0) {
          $('#tribe-notification-bubble').show();
        } else {
          $('#tribe-notification-bubble').hide();
        }
      }

      if(data.tribe) {
        $(".dropdown.open").toggle();
      }
    }, false);

    if (document.getElementById('home-quotes') !== null) {

      var $quotes = $("#home-quotes");
      var windowH = window.innerHeight;

      $(window).scroll(function() {
        var scrollY = $(window).scrollTop();
        var quotesOffset = $quotes.offset();
        var quotesY = quotesOffset.top - windowH;
        console.log(scrollY, quotesY)

        if (scrollY >= quotesY) {
          $quotes.addClass('in-viewport');
        }
      });
    }
  }
}