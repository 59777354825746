if (typeof Itinsy == "undefined") { Itinsy = {} }

Itinsy.Map = (function() {
  var map = null;
  var mapper = {};
  var markers = [];
  var markerBounds = null;
  var boundsEvent = true; // change search results on map move

  mapper.getMap = function() {
    return map
  }

  mapper.delayedInitialize = function(callback) {
    if(typeof mapboxgl == 'undefined') {
      setTimeout(function() { Itinsy.Map.delayedInitialize(callback) }, 50)
    } else {
      Itinsy.Map.initialize(callback)
    }
  }

  mapper.initialize = function(callback) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiaXRpbnN5IiwiYSI6ImNrYzR0MXo5cjA2N3czMHF2MG1jYWRpYnAifQ.ORP_-IlnA1UnC5ZmhHEzCQ';
    markerBounds = new mapboxgl.LngLatBounds()
    if(!mapboxgl.supported()) {
      alert("Your web browser has WebGL disabled. Please enable WebGL to use our maps.")
    }

    if(typeof callback !== 'undefined') {
      callback()
    }
  }

  mapper.showMap = function(settings) {
    settings.style = settings.style || 'mapbox://styles/mapbox/streets-v11'
    map = new mapboxgl.Map(settings)
    if(!settings.skipFit) {
      mapper.fitToMarkers()
    }
    map.setMinZoom(1.0469265675779744)
    map.addControl(new mapboxgl.NavigationControl());

    map.on('moveend', () => {
      if(typeof window.boundsChanged !== undefined && boundsEvent) {
        window.boundsChanged(map.getBounds())
      }
    })
  }

  // Enable changing search results on map move
  mapper.enableBoundsEvent = function() {
    boundsEvent = true
  }

  // Disable changing search results on map mpve
  mapper.disableBoundsEvent = function() {
    boundsEvent = false
  }

  mapper.centerMarker = function(id) {
    var marker = markers.find(function(marker) {
      return marker.extraData.id == id
    })
    map.setCenter(marker.getLngLat())
  }

  mapper.addProfileMarker = function(settings, url) {
    var marker = new mapboxgl.Marker()
    if(!settings.lat || !settings.id) { return }
    
    marker.setLngLat([parseFloat(settings.lng), parseFloat(settings.lat)])
    marker.extraData = settings

    let html = `<strong>${settings.name}</strong>`
    html += `<div>[<a href="${url}">View Itinerary</a>]</div>`

    var popup = new mapboxgl.Popup()
     .setHTML(html)
     .setMaxWidth("300px");
    marker.setPopup(popup);
    marker.getElement().style.cursor = 'pointer';
    if(settings.color) {
      this.setMarkerColor(marker, settings.color)
    }

    markers.push(marker)
    markerBounds.extend(marker.getLngLat())
  }

  // Draw lines between locations on the map
  mapper.drawRoute = function() {
    var markerLngLat = markers.map(function(marker) { 
      var lnglat = marker.getLngLat() 
      return [lnglat.lng, lnglat.lat]
    });
    if(markerLngLat.length == 0) { return }

    map.on('load', function() {
      map.addSource('route', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': {
            'type': 'LineString',
            'coordinates': markerLngLat
          }
        }
      })
      map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round',
          'line-join': 'round',
        },
        'paint': {
          'line-dasharray': [2, 2],
          'line-color': '#888',
          'line-width': 5,
          'line-opacity': 0.6,
        }
      });
    })
  }

  mapper.addShowMarker = function(settings, url) {
    // create a HTML element for each feature
    var el = document.createElement('div');
    el.className = 'number-marker';
    el.innerHTML = '<span><b>' + (markers.length + 1) + '</b></span>'

    var marker = new mapboxgl.Marker(el)
    if(!settings.lat || !settings.id) { return }
    marker.setLngLat([parseFloat(settings.lng), parseFloat(settings.lat)])
    marker.extraData = settings
    marker.getElement().addEventListener('click', () => {
      Rails.ajax({url: url, type: "GET", dataType: 'script'})
    });
    let html = `<strong>${settings.name}</strong>`
    if(settings.count != null) {
      html += `<div>[<a href='#' onclick="filterToGeoId('${settings.name}', ${settings.geoid}); return false;">filter</a>]</div>`
    }

    var popup = new mapboxgl.Popup().setHTML(html).setMaxWidth("300px");

    marker.setPopup(popup);
    marker.getElement().style.cursor = 'pointer';
    markers.push(marker)
    markerBounds.extend(marker.getLngLat())
  }

  mapper.addSearchMarker = function(settings) {
    var marker = new mapboxgl.Marker()
    if(!settings.lat || !settings.id) { return }
    marker.setLngLat([parseFloat(settings.lng), parseFloat(settings.lat)])
    marker.extraData = settings

    let html = `<strong>${settings.name}</strong>`
    if(settings.count != null) {
      html += `<div>${settings.count} matching itineraries [<a href='#' onclick="filterToGeoId('${settings.name}', ${settings.geoid}); return false;">filter</a>]</div>`
    }

    var popup = new mapboxgl.Popup().setHTML(html).setMaxWidth("300px");
    marker.setPopup(popup);
    marker.getElement().style.cursor = 'pointer';

    popup.on('open', function() {
      mapper.showItinerarySearchResult(settings.itinerary_id)
    });

    markers.push(marker)

    markerBounds.extend(marker.getLngLat())
  }

  mapper.showItinerarySearchResult = function(id) {
    var element = document.getElementById('itinerary-' + id)
    element.scrollIntoView({behavior: "smooth", block: "start"})
  }

  mapper.hideShow = function(shower) {
    markers.forEach((m) => {
      if(shower(m)) {
        m.addTo(map)
      } else {
        m.remove()
      }
    })
  }

  mapper.setMarkerColor = function(marker, color) {
    var $elem = jQuery(marker.getElement());
    $elem.find('svg g[fill="' + marker._color + '"]').attr('fill', color);
    marker._color = color;
  }

  mapper.removeHighlight = function(geoId) {
    markers.forEach((m) => {
      if(m._color != '#3FB1CE') {
        this.setMarkerColor(m, '#3FB1CE')
      }
    })
  }

  mapper.highlightMarkerByGeoId = function(geoid) {
    markers.forEach((m) => {
      if(m.extraData.geoid == geoid) {
        this.setMarkerColor(m, '#558354')
      }
    })
  }

  mapper.showMarkers = function() {
    markers.forEach(function(marker) {
      marker.addTo(map)
    })
  }

  mapper.setMapDefaultLocation = function() {
    map.setCenter({lng: -32.571417933055045, lat: 21.075792481845014})
    map.setZoom(1.0569265675779744)
  }

  mapper.fitToMarkers = function() {
    if(markers.length > 1) {
      map.fitBounds(markerBounds, {padding: 100, duration: 0})
    } else if(markers.length == 1) {
      map.setCenter(markerBounds.getCenter())
    } else {
      mapper.setMapDefaultLocation()
    }
  }

  mapper.clearMarkers = function() {
    markerBounds = new mapboxgl.LngLatBounds()
    markers.forEach(function(marker) {
      marker.remove()
    });
    markers = []
  }

  return mapper;
}());
