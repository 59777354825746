// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.$ = window.jQuery = require("jquery-slim");

global.Rails = require("@rails/ujs")
global.Rails.start()

// require("@rails/activestorage").start()
// require("application/public/bootstrap");
require("application/public/map");
require("application/public/itinerary/map_toggle");

//Sortable = require("application/vendor/sortable")
//require("application/vendor/jquery-sortable")
//require("application/portal/sorting")

require("application/public/carousel")
require("application/public/page_components")
require("application/public/marketing_message")
require("application/public/cookies")

import ReactOnRails from 'react-on-rails'
import FilterBar from 'application/react_components/search/FilterBar'
import HeaderSearch from 'application/react_components/search/HeaderSearch'
import EmojiRating from 'application/react_components/itinerary/ratings/EmojiRating'
import MultiEmailInput from 'application/react_components/ui/MultiEmailInput'
import ContactsImport from 'application/react_components/contacts/ContactsImport'

ReactOnRails.register({
  ContactsImport,
  EmojiRating,
  FilterBar,
  HeaderSearch,
  MultiEmailInput
});


$(document).ready(function() {
  //Sorting.initialize();
  PageComponents.initialize();
  Carousel.initCardCarousel();
  //Carousel.initDestCarousel();

  document.body.addEventListener('ajax:error', function(event) {
    var detail = event.detail;
    var data = detail[0], status = detail[1], xhr = detail[2];
    alert("There was an error sending your request to the server, please try again.")
  })
})


