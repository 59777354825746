import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import classnames from 'classnames';
import { Tooltip } from 'reactstrap';

class UsersList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      retrieving: false,
      retrieved: false,
      users: [],
      insightfulTooltipOpen: false,
      likeTooltipOpen: false
    }
  }

  componentDidMount() {
    if(!this.state.retrieved) {
      this.getRatingUsers()
    }
  }

  componentWillUnmount() {
  //  console.log("unmount")
  }

  getRatingUsers = () => {
    const opts = {
      rating_type: this.props.type,
      model_id: this.props.modelId,
      model_type: this.props.modelType
    }

    const headers = {
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    }

    this.setState({retrieving: true})
    return axios.post('/itinerary/rating_users', opts, headers)
      .then((response) => {
        this.setState({users: response.data.users, retrieving: false, retrieved: true})
      })
      .catch((error) => {
        this.setState({retrieving: false})
      })
  }

  render() {
    if(!this.state.retrieved) {
      return <span><i className="fas fa-spinner fa-spin"></i></span>
    }
    return(
      this.state.users.map((user) =>
        user.profile_path ?
          <div key={user.id}><a key={user.id} href={user.profile_path ? user.profile_path : '#'} className="text-white">{user.profile_username}</a></div>
        : 
          <div key={user.id}>{user.profile_username}</div>
      )
    )
  }
}

class EmojiRating extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      counts: this.props.counts,
      comments_count: this.props.comments_count,
      href: this.props.href,
      alreadyRated: this.props.alreadyRated || {},
      sendingToServer: false
    }
  }

  sendRatingToServer = (ratingType) => {
    const opts = {
      rating_type: ratingType,
    }

    const headers = {
      headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
    }

    return axios.post(this.props.url, opts, headers)
      .then((response) => {
        this.setState({sendingToServer: false})
        setTimeout(() => {
          this.setState({showAdded: false})
        }, 500);
      })
      .catch((error) => {
        const counts = {...this.state.counts}
        if(counts[ratingType] > 0) {
          counts[ratingType] -= 1
        }
        const alreadyRated = {...this.state.alreadyRated}
        alreadyRated[ratingType] = false
        this.setState({counts: counts, alreadyRated: alreadyRated, sendingToServer: false})
        alert("There was an error adding your rating, please try again.")
      })
  }

  onEmojiClick = (ratingType) => {
    if(!this.state.alreadyRated[ratingType]) {
      if (this.state.sendingToServer) { return; }
      this.setState({sendingToServer: true});

      const counts = {...this.state.counts}
      counts[ratingType] += 1
      const alreadyRated = {...this.state.alreadyRated}
      alreadyRated[ratingType] = true
      this.setState({counts: counts, alreadyRated: alreadyRated})

      this.sendRatingToServer(ratingType)
    }
  }

  likeTooltipToggle = (event) => {
    this.setState((state) => ({likeTooltipOpen: !state.likeTooltipOpen}))
  }

  insightfulTooltipToggle = (event) => {
    this.setState((state) => ({insightfulTooltipOpen: !state.insightfulTooltipOpen}))
  }

  render() {
    //const { rating } = this.state;
    //const clickable = this.state.alreadyRated ? {} : {cursor: "pointer"}
    const insightfuled = this.state.alreadyRated.insightful
    const liked = this.state.alreadyRated.like

    return (
      <div className="emojis">
        <span className={classnames("emoji emoji--btn", {"cursor-pointer": !liked})}
          id={"emoji-like-" + this.props.model_id}
          aria-label="Thumbs Up"
          onClick={(e) => this.onEmojiClick('like')}>
          <span className="emoji__icon">&#x1F44D;</span>
          <span className="emoji__count">{this.state.counts.like}</span>
        </span>
        <Tooltip
          placement={liked ? "bottom" : "bottom"}
          target={"emoji-like-" + this.props.model_id}
          className="tooltip--listing"
          autohide={false}
          toggle={this.likeTooltipToggle}
          isOpen={this.state.likeTooltipOpen}
        >
          {!liked || this.state.sendingToServer ? <span>Like</span> : <UsersList modelId={this.props.model_id} modelType={this.props.model_type} type={"like"} showing={this.state.likeTooltipOpen} />}
        </Tooltip>

        <span className={classnames("emoji emoji--btn", {"cursor-pointer": !insightfuled})}
          id={"emoji-insightful-" + this.props.model_id}
          aria-label="Light Bulb"
          onClick={(e) => this.onEmojiClick('insightful')}>
          <span className="emoji__icon">&#x1F4A1;</span>
          <span className="emoji__count">{this.state.counts.insightful}</span>
        </span>
        <Tooltip
          placement={insightfuled ? "bottom" : "bottom"}
          target={"emoji-insightful-" + this.props.model_id}
          className="tooltip--listing"
          autohide={false}
          delay={50}
          toggle={this.insightfulTooltipToggle}
          isOpen={this.state.insightfulTooltipOpen}
        >
          {!insightfuled || this.state.sendingToServer ? <span>Insightful</span> : <UsersList modelId={this.props.model_id} modelType={this.props.model_type} type={"insightful"} showing={this.state.insightfulTooltipOpen} />}
        </Tooltip>

        <span className="emojis__divider"></span>

        <a className={classnames("emoji emoji--btn emoji--separate")}
          title="Comments"
          href={this.state.href + "#comments-container"}
          data-toggle="tooltip"
          aria-label="Comments">
          <i className="emoji__icon fas fa-comments text-primary"></i>
          <span className="emoji__count">{this.state.comments_count}</span>
        </a>
      </div>
    )
  }
}

export default EmojiRating