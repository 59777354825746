import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import axios from 'axios'
import classnames from 'classnames'
import Autocomplete from '../../ui/Autocomplete'
import getMeta from '../../utils/Meta'

class GeoAutocomplete extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [],
      selected: [],
    }
  }

  onSelectedChange = (selected) => {
    if(this.props.onSelectedChange) {
      this.props.onSelectedChange(selected)
    } else {
      this.setState({selected: selected})
    }
  }

  onSearchChange = (state) => {
    const opts = {
      method: 'get',
      url: '/geo/search.json',
      params: {q: state.searchValue},
      headers: { 'X-CSRF-Token': getMeta('csrf-token') }
    }
    if(this.props.countries) {
      opts.params.countries = this.props.countries.map((c) => c.value)
    }
    if(this.props.countriesOnly) {
      opts.params.countriesOnly = true
    }
    axios(opts)
      .then((response) => {
        const results = response.data.results
        this.setState({options: results})
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    return(
      <Autocomplete
        divId={this.props.divId}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder || "Search for Itineraries"}
        options={this.state.options}
        onSubmit={this.props.onSubmit}
        onSearchChange={this.onSearchChange}
        onSelectedChange={this.onSelectedChange}
        selected={this.props.selected || this.state.selected}
        hideMagnifier={this.props.hideMagnifier}
        magnifierClick={this.props.magnifierClick}
      />
    )
  }

}

export default GeoAutocomplete
