Itinsy.MapToggle = Itinsy.MapToggle || {}

Itinsy.MapToggle = {
  showMap: function() {
    $(".show-topper").addClass('show-map')
    // $("#map-area").show();
    $(".map-toggle").html("<i class='fa fa-image icon'></i> Photos")
  },

  showPhotos: function() {
    $(".show-topper").removeClass('show-map')
    // $("#map-area").hide();
    $(".map-toggle").html("<i class='fa fa-map icon'></i> Map")
  },

  toggle: function() {
    if($(".show-topper").hasClass('show-map')) {
      this.showPhotos();
    } else {
      this.showMap();
    }
  }
}